import React from 'react'
import PropTypes from "prop-types";
import {NavLink} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Title from "../page/Title";
import Box from "../box/Box";
import ComptaMenu from "../../pages/comptabilite/ComptaMenu";


const ComptaLayout = ({content}) => {
  return (
    <>
      <Title title="Ma comptabilité" />

      <Box
        boxName="prelevements"
        boxClassNames="flex-column"
        boxContentBg="white"
        content={
          <>
            <NavLink
              eventKey={100}
              href="https://safety.florajet.com"
              className="btn btn-outline-blue py-1 px-3 px-sm-4 my-1 my-sm-2 mr-sm-2 w-fit-content"
              title="Coffre-fort"
              target="_blank"
            >
              <FontAwesomeIcon icon="lock" color="white" className="mr-1" />
              Coffre-fort
            </NavLink>

            <ComptaMenu />

            {content}
          </>
        }
      />
    </>
  )
}

ComptaLayout.propTypes = {
  content: PropTypes.node
}

export default ComptaLayout
